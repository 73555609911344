import React from 'react';
import { Helmet } from 'react-helmet';
import { FaEnvelope, FaMapMarkerAlt, FaMobileAlt } from 'react-icons/fa'
import misc from '../misc.json';

const Contact = () => {
    return (
        <div className="contact-row-container">
            <Helmet>
                <title>Հետադարձ կապ - Տպարան․am</title>
                <meta name="description" content={misc['meta']['description'] + "Տպագրություն, Հրատարակչություն և Դիզայն: Գունավոր թվային/լազերային և օֆսեթ տպագրություն. Գովազդային նյութերի պատրաստում: Տպագրություն շապիկների և բաժակներ վրա: Բուկլետներ, գրքեր, վիզիտկա, դիզայն, ժուրնալ, ամսագրեր, գունաբաժանում, կազմարարական աշխատանքներ, տպագրատուն, տպարան, տպագրություն, պատի օրացույց, սեղանի օրացույց, tpagrutyun, tpagrutsyun, tparan, oracuyc, pati oracuyc, seghani oracuyc"}/>
                <meta name="keywords" content={misc['meta']['keywords']}/>
            </Helmet>
            <div className="fh5co-more-contact">
                <div className="fh5co-narrow-content">
                        <div className="row">
                            <div className="col-md-4" style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <div className="row">
                                    <FaEnvelope size="1.5em" style={{
                                        marginBottom: "1rem",
                                        display: 'block',
                                        color: '#02B1EC'
                                    }} />
                                </div>
                                <div className="row">
                                    <a  className="contact-text contact-email"
                                        href="mailto:tparan@tparan.am">
                                        tparan@tparan.am
                                    </a>
                                </div>
                                <div className="row">
                                    <a  className="contact-text contact-email"
                                        href="mailto:info@tparan.am">
                                        info@tparan.am
                                    </a>
                                </div>
                                <div className="row">
                                    <a  className="contact-text contact-email"
                                        href="mailto:marketing@tparan.am">
                                        marketing@tparan.am
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                                <div className="row">
                                    <FaMapMarkerAlt size="1.5em" style={{
                                        marginBottom: "1rem",
                                        display: 'block',
                                        color: '#02B1EC'
                                    }} />
                                </div>
                                <div className="row">
                                    <p className="contact-text">
                                        Մամուլի շենք /Արշակունյաց 4/, 8-րդ հարկ<br/>
                                        IT Park բիզնես կենտրոն<br/>
                                        Գյուրջյան 15/25 /Նոր Նորք մասնաճյուղ/
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", textAlign: "right" }}>
                                <div className="row">
                                    <FaMobileAlt size="1.5em" style={{
                                        marginBottom: "1rem",
                                        display: 'block',
                                        color: '#02B1EC'
                                    }} />
                                </div>
                                <div className="row">
                                    <p className="contact-text">
                                        Հեռ. (+374 60) 446685<br />
                                        Բջջ. (+374 91) 407038<br />
                                        (+374 93) 407038
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <iframe title="maps" src="https://www.google.com/maps/d/u/0/embed?mid=1xqKP3svNQrV9HgllYMo3gvD1rsyM23bw" className="contact-map"></iframe>
        </div>
    );
}

export default Contact;