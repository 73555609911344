import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './css/index.css';
import './css/animate.css';
import './css/carousel.min.css';
import AppRouter from './routes/AppRouter';
import * as serviceWorker from './serviceWorker';

import { createRoot, hydrateRoot } from 'react-dom/client';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
.use(initReactI18next) 
.init({
  lng: "hy",
  fallbackLng: "hy",
  resources: {
    hy: {
      translation: require("./locales/hy/translation.json")
    }
  }
});



const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  const root = hydrateRoot(rootElement)
  root.render(<AppRouter />);
} else {
  const root = createRoot(rootElement)
  root.render(<AppRouter />);
}

// render(<AppRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
