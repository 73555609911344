import React from "react";
import PropTypes from "prop-types";
import MaterialTitlePanel from "./material_title_panel";
import { NavLink } from 'react-router-dom';
import data from '../products.json';

const styles = {
    sidebar: {
      width: 230,
      minHeight: "100%",
      backgroundColor: "white"
    },
    sidebarLink: {
      display: "block",
      padding: "16px 0px",
      color: "#757575",
      textDecoration: "none"
    },
    divider: {
      margin: "8px 0",
      height: 1,
      backgroundColor: "#757575"
    },
    content: {
      padding: "16px",
      height: "100%",
      backgroundColor: "white"
    }
};

const SidebarContent = props => {
  const style = props.style
    ? { ...styles.sidebar, ...props.style }
    : styles.sidebar;

  let final_tags = []
  let logo = process.env.PUBLIC_URL + '/uploads/logo1.webp'

  data.forEach(
      item => {
          final_tags.push(
            <li>
              <NavLink
                  key={item['title'] + item['image']}
                  className="navlink"
                  activeClassName="product-is-active"
                  to={"/product/" + item['title']}
                  onClick={props.toggleSidebar}
                //   style={{ fontSize: item['font'] + 'px' }}
                  >
                      {item['title']} </NavLink>
                      
                      <hr style={{
                        width: "150px",
                        marginRight: "auto",
                        marginLeft: "auto",
                        border: "none",
                        height: "1px",
                        backgroundColor: "#EC268F",
                        }} />
                      </li>
            )
      }
  )

  return (
    <MaterialTitlePanel style={style} showHeader={false}>
      <div className="">
            <br></br>
            <nav className="fh5co-main-menu">
                <div className="mainMenuContainer">
                    <ul>
                        <li>
                            <NavLink className="navlink no-hover" to="/" exact={true}>
                                <img src={logo} alt="" style={{ width: 200 }}/>
                            </NavLink>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <NavLink
                            className="navlink"
                            activeClassName="is-active"
                            to="/"
                            onClick={props.toggleSidebar}
                            exact={true}>
                                Գլխավոր
                            </NavLink>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <NavLink
                            className="navlink"
                            activeClassName="is-active"
                            to={{pathname: "https://instagram.com/tparan.am"}} // /portfolio
                            target="_blank"
                            onClick={props.toggleSidebar}
                            exact={true}>
                                Պորտֆոլիո
                            </NavLink>
                        </li>
                    </ul>
                    {/* <ul>
                        <li>
                            <NavLink className="navlink" activeClassName="is-active" to="/games">
                                Ժամանց
                            </NavLink>
                        </li>
                    </ul> */}
                    {/* <ul>
                        <li>
                            <NavLink className="navlink" activeClassName="is-active" to="/work">
                                Աշխատատեղեր
                            </NavLink>
                        </li>
                    </ul> */}
                    <ul>
                        <li>
                            <NavLink
                            className="navlink"
                            activeClassName="is-active"
                            to = "/about"
                            onClick={props.toggleSidebar}
                            exact={true}>
                                Մեր Մասին
                            </NavLink>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <NavLink
                            className="navlink"
                            activeClassName="is-active"
                            to="/contact"
                            onClick={props.toggleSidebar}
                            exact={true}>
                                Հետադարձ կապ
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <hr />
                <ul className="productsContainer">
                    {final_tags}
                </ul>
                <div id="tags">
                    
                </div>
            </nav>
        </div>
    </MaterialTitlePanel>
  );
};

SidebarContent.propTypes = {
  style: PropTypes.object
};

export default SidebarContent;