export const generateFullTitle = (item) => {
    if (item['custom_full_title'] !== undefined) {
        return item['custom_full_title']
    }

    if (item['custom_title_extension'] !== undefined) {
        return item['title'] + item['custom_title_extension']
    }

    let newTitle = item['title']

    // if newTitle ends with non-alphabetic (including unicode) character, add 'ի' before it
    if (newTitle.match(/[^\u0531-\u0587\u0561-\u0587a-zA-Z0-9]$/)) {
        // add 'ի' before the last character
        newTitle = newTitle.slice(0, -1) + 'ի' + newTitle.slice(-1)
    }
    else {
        // add 'ի' at the end
        newTitle = newTitle + 'ի'
    }

    if (item['colored'] !== 0) {
        newTitle = newTitle + ' գունավոր'
    }

    return newTitle + " տպագրություն"


    // return `${newTitle}ի ${item['colored'] !== 0 ? "գունավոր " : ""}տպագրություն`
}