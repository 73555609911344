import React, { Component } from 'react';
// import Instagram from 'node-instagram';
import { Helmet } from 'react-helmet';
import misc from "../misc.json";

class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.state = { data: [] }
    }

    async componentDidMount() {
        // const instagram = new Instagram({
        //     clientId: '1604065098b54429a583cdc154996bf9',
        //     clientSecret: 'abc5d29982af439791ada75f5d9e6b8c',
        //     accessToken: '3176151770.1604065.052c0ae2ba044251bd4cb7a06e6f623d'
        // })

        // const response = await instagram.get('users/self/media/recent')
        // this.setState({data: response['data']})
    }

    render() {
        return (
            // <div>
            //     <Helmet>
            //         <title>Պորտֆոլիո - Տպարան․am</title>
            //         <meta name="description" content="Տպարան․am Պորտֆոլիո․ Այցեքարտեր, բուկլետներ, օրացույցներ, թռուցիկներ և այլն։"/>
            //     </Helmet>
            //     <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>
            // </div>

            <div className="portfolio-container">
                <Helmet>
                    <title>Պորտֆոլիո - Տպարան․am</title>
                    <meta name="description" content={misc['meta']['description'] + " Տպարան․am Պորտֆոլիո․ Այցեքարտեր, բուկլետներ, օրացույցներ, թռուցիկներ և այլն։ Տպագրություն, Հրատարակչություն և Դիզայն: Գունավոր թվային/լազերային և օֆսեթ տպագրություն. Գովազդային նյութերի պատրաստում: Տպագրություն շապիկների և բաժակներ վրա: Բուկլետներ, գրքեր, վիզիտկա, դիզայն, ժուրնալ, ամսագրեր, գունաբաժանում, կազմարարական աշխատանքներ, տպագրատուն, տպարան, տպագրություն, պատի օրացույց, սեղանի օրացույց, tpagrutyun, tpagrutsyun, tparan, oracuyc, pati oracuyc, seghani oracuyc"}/>
                    <meta name="keywords" content={misc['meta']['keywords']}/>
                </Helmet>
                {
                    this.state.data.map((obj) => {
                        return  <div>
                            <a
                                href={obj['link']}>
                                <img src={obj['images']['low_resolution']['url']}
                                className="portfolio-img"
                                alt=""/>
                            </a>
                            </div>;
                    })
                }
                <div>
                    <a href="https://instagram.com/tparan.am" style={{position: "relative", textAlign: "center"}}>
                        <div className="more_link">Ավելին․․․</div>
                        <img style={{opacity: "0"}}
                        width="250px"
                        height="250px"
                        className="portfolio-img more"
                        alt=""/>
                        <a className="more_link" href="https://instagram.com/tparan.am">Ավելին․․․</a>
                    </a>
                </div>


            </div>
        );
    }
}

export default Portfolio;