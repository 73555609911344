import React from "react";
import { useMediaPredicate } from "react-media-hook";
import PropTypes from "prop-types";

// const mql = window.matchMedia(`(max-width: 800px)`);

const styles = {
  root: {
    fontFamily:
      '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif'
  },
  header: (isMobile) =>  ({
    // backgroundColor: "#03a9f4",
    // background: "rgba(0, 0, 0, 0.8) none repeat scroll 0% 0%",
    color: "rgba(0, 0, 0, 0.8)",
    padding: "16px",
    fontSize: "2em",
    backgroundColor: "white",
    width: "100%",
    textAlign: "center",
    display: isMobile ? "block" : "none",
  })
};



const MaterialTitlePanel = props => {
  const isMobile = useMediaPredicate("(max-width: 768px)");

  const rootStyle = props.style
    ? { ...styles.root, ...props.style }
    : styles.root;

  const header = true //mql.matches && props.showHeader
    ? <header style={styles.header(isMobile)}>{props.title}</header>
    : null

  return (
    <div style={rootStyle}>
      {header}
      {props.children}
    </div>
  );
};

MaterialTitlePanel.propTypes = {
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.object,
  showHeader: PropTypes.bool
};

export default MaterialTitlePanel;