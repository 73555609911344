import React from 'react';
import { Helmet } from 'react-helmet';
import misc from '../misc.json';

const NotFound = () => {
    return (
    <div>
        <Helmet>
            <title>404 - Տպարան․am</title>
            <meta name="description" content={misc['meta']['description']} />
            <meta name="keywords" content={misc['meta']['keywords']} />
        </Helmet>
        404 - Չի գտնվել
    </div>
    );
}

export default NotFound;