import React from 'react';
import Sidebar from "react-sidebar";
import SidebarContent from '../components/SidebarContent';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from '../components/App';
import About from '../components/About';
import Portfolio from '../components/Portfolio';
import Product from '../components/Product';
import Contact from '../components/Contact';
import NotFound from '../components/NotFound';
import MaterialTitlePanel from "../components/material_title_panel";
import { NavLink } from 'react-router-dom';


const styles = {
    contentHeaderMenuLink: {
        textDecoration: "none",
        color: "rgba(0, 0, 0, 0.8)",
        padding: 8,
        position: "absolute",
        left: "15px"
    },
    content: {
        padding: "16px"
    }
};


const mql = window.matchMedia(`(min-width: 768px)`);

class AppRouter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            docked: mql.matches,
            open: false,
            transitions: !mql.matches,
            touch: true,
            shadow: true,
            pullRight: false,
            touchHandleWidth: 20,
            dragToggleDistance: 30
        };

        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.onSetOpen = this.onSetOpen.bind(this);
    }

    componentDidMount() {
        mql.addListener(this.mediaQueryChanged);
    }

    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }

    onSetOpen(open) {
        this.setState({ open });
    }

    mediaQueryChanged() {
        this.setState({
            docked: mql.matches,
            open: false,
            transitions: !mql.matches
        });
    }

    toggleOpen(ev) {
        this.setState({ open: !this.state.open });

        if (ev) {
            ev.preventDefault();
        }
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    toggleSidebar(ev) {
        this.sleep(0).then(() => {
            this.setState({ open: !this.state.open });

            if (ev) {
                ev.preventDefault();
            }
        })

    }

    render() {
        const sidebar = <SidebarContent toggleSidebar={this.toggleSidebar}/>;
        let logo = process.env.PUBLIC_URL + '/uploads/logo1.webp'

        const contentHeader = (
            <div>
                {!this.state.docked && (
                    <a
                        onClick={this.toggleOpen}
                        href="/"
                        style={styles.contentHeaderMenuLink}
                    >
                        =
                    </a>
                )}
                <NavLink className="navlink" to="/" exact={true}>
                    <img src={logo} alt="" style={{ width: 200 }}/>
                </NavLink>
            </div>
        );


        const sidebarProps = {
            sidebar,
            docked: this.state.docked,
            open: this.state.open,
            touch: this.state.touch,
            shadow: this.state.shadow,
            pullRight: this.state.pullRight,
            touchHandleWidth: this.state.touchHandleWidth,
            dragToggleDistance: this.state.dragToggleDistance,
            transitions: this.state.transitions,
            onSetOpen: this.onSetOpen
        };

        return (
            <BrowserRouter>
                <Sidebar {...sidebarProps}>
                    <MaterialTitlePanel title={contentHeader} showHeader={true}>
                        <Switch>
                            <Route path="/" component={App} exact={true} />
                            <Route path="/about" component={About}/>
                            <Route path="/portfolio" component={Portfolio} exact={true} />
                            <Route path="/product/:id" component={Product} />
                            <Route path="/contact" component={Contact} />
                            <Route component={NotFound} status={404} />
                        </Switch>
                    </MaterialTitlePanel>
                </Sidebar>
            </BrowserRouter>
        );
    }
}

export default AppRouter;