import React from 'react';
import CountUp from 'react-countup';
import products from '../products.json';
import misc from '../misc.json';
import { FaEnvelope, FaMapMarkerAlt, FaMobileAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import { Fade } from 'react-slideshow-image';
// import { Carousel } from 'react-responsive-carousel';

import { useTranslation } from 'react-i18next';

import { Masonry } from '@mui/lab';
import { generateFullTitle } from '../helpers';

const App = () => {
    const { t } = useTranslation();

    let clients = process.env.PUBLIC_URL+'/uploads/clients.webp?new1000'
    let home = process.env.PUBLIC_URL+'/uploads/home.webp?new'
    // let akcia = process.env.PUBLIC_URL+'/uploads/akcia.webp'
    let final_products = []
    // let final_slides = []
    // const fadeImages = [
    //     // 'uploads/slideshow/slide0.webp',
    //     'uploads/slideshow/slide1.webp',
    //     'uploads/slideshow/slide2.webp',
    //     'uploads/slideshow/slide3.webp',
    //     'uploads/slideshow/slide4.webp',
    //     'uploads/slideshow/slide5.webp',
    //     'uploads/slideshow/slide6.webp',
    //     'uploads/slideshow/slide7.webp',
    //     'uploads/slideshow/slide8.webp',
    //     'uploads/slideshow/slide9.webp',
    //     'uploads/slideshow/slide10.webp',
    //     'uploads/slideshow/slide11.webp',
    //     'uploads/slideshow/slide12.webp',
    //     'uploads/slideshow/slide13.webp',
    //     'uploads/slideshow/slide14.webp',
    //     'uploads/slideshow/slide15.webp',
    //     'uploads/slideshow/slide16.webp',
    //     'uploads/slideshow/slide17.webp',
    // ];
    // $100 offers
    // const fadeImages = [
    //     'uploads/tparanam_8_nkar/slide1.webp',
    //     'uploads/tparanam_8_nkar/slide2.webp',
    //     'uploads/tparanam_8_nkar/slide3.webp',
    //     'uploads/tparanam_8_nkar/slide4.webp',
    //     'uploads/tparanam_8_nkar/slide5.webp',
    //     'uploads/tparanam_8_nkar/slide6.webp',
    //     'uploads/tparanam_8_nkar/slide7.webp',
    //     'uploads/tparanam_8_nkar/slide8.webp',
    // ]

    // const properties = {
    //     showArrows: true,
    //     // onChange: {onChange},
    //     // onClickItem: {onClickItem},
    //     // onClickThumb: {onClickThumb},
    //     autoPlay: true,
    //     showIndicators: true,
    //     infiniteLoop: true,
    //     useKeyboardArrows: true,
    //     stopOnHover: false,
    //     swipeable: true,
    //     showStatus: false,
    //     showThumbs: true,
    //     thumbWidth: 100,
    //     thumbHeight: 100,
    //     interval: 5000,
    //     transitionTime: 500,
    //     dynamicHeight: false


    //     // onChange: (oldIndex, newIndex) => {
    //     //   console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    //     // }
    // }

    products.forEach(item => {
        if(item['visible'] !== 0){
            let path = item['image']
            if (typeof(item['image']) == 'object'){
                path = item['image'][0]
            }
            final_products.push(
                <div className="work-item" key={item['title'] + item['image']}>
                    <Link to={"/product/" + item['title']}>
                        <img src={process.env.PUBLIC_URL + '/uploads/products/' + path}
                            alt={item['title']} className="img-responsive products" />
                        <h6 className="fh5co-work-title">{generateFullTitle(item)}</h6>
                        {/* <p>****************</p> */}
                    </Link>
                </div>
            )
        }
    })

    // fadeImages.forEach( item => {
    //     final_slides.push(
    //         <div key={item}>
    //             <img src={item} alt=''/>
    //         </div>
    //     )}
    // )

    return (
        <div>
            <Helmet>
                <title>{t('title')}</title>
                <meta name="description" content={misc['meta']['description'] + "Տպագրություն, Հրատարակչություն և Դիզայն: Գունավոր թվային/լազերային և օֆսեթ տպագրություն. Գովազդային նյութերի պատրաստում: Տպագրություն շապիկների և բաժակներ վրա: Բուկլետներ, գրքեր, վիզիտկա, դիզայն, ժուրնալ, ամսագրեր, գունաբաժանում, կազմարարական աշխատանքներ, տպագրատուն, տպարան, տպագրություն, պատի օրացույց, սեղանի օրացույց, tpagrutyun, tpagrutsyun, tparan, oracuyc, pati oracuyc, seghani oracuyc"}/>
                <meta name="keywords" content={misc['meta']['keywords']}/>
            </Helmet>

            <div>
                <div className="fh5co-narrow-content">
                    <div className="row">
                        <div className="col-md-3 text-center" style={{marginBottom: '20px'}}>
                            <div className="row">
                                <FaEnvelope size="2em" style={{
                                    padding: '0 5px 10px 0',
                                    display: 'block',
                                    margin: '0 auto',
                                    color: '#02B1EC'
                                }} />
                            </div>
                            <div className="row">
                                <a  className="contact-text contact-email"
                                    href="mailto:tparan@tparan.am">
                                    tparan@tparan.am
                                </a>
                            </div>
                            <div className="row">
                                <a  className="contact-text contact-email"
                                    href="mailto:info@tparan.am">
                                    info@tparan.am
                                </a>
                            </div>
                            <div className="row">
                                <a  className="contact-text contact-email"
                                    href="mailto:marketing@tparan.am">
                                    marketing@tparan.am
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 text-center" style={{marginBottom: '20px'}}>
                            <div className="row">
                                <FaMapMarkerAlt size="2em" style={{
                                    padding: '0 5px 10px 0',
                                    display: 'block',
                                    margin: '0 auto',
                                    color: '#02B1EC'
                                }} />
                            </div>
                            <div className="row">
                                <p className="contact-text">
                                    {t('addr_mamuli')}<br/>
                                    {t('addr_itpark')}<br/>
                                    {t('addr_masiv')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3 text-center" style={{marginBottom: '20px'}}>
                            <div className="row">
                                <FaMobileAlt size="2em" style={{
                                    padding: '0 5px 10px 0',
                                    display: 'block',
                                    margin: '0 auto',
                                    color: '#02B1EC'
                                }} />
                            </div>
                            <div className="row">
                                <p className="contact-text">
                                    {t('tel')} (+374 60) 446685<br />
                                    {t('mob')} (+374 91) 407038<br />
                                    (+374 93) 407038
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3 text-center social-media" style={{marginBottom: '20px'}}>
                            <a href="https://instagram.com/tparan.am">
                                <img width="48" height="48" src="https://img.icons8.com/fluency/144/instagram-new.png" alt="instagram-new"/>
                            </a>

                            <a href="https://facebook.com/tparan.am">
                                <img width="48" height="48" src="https://img.icons8.com/fluency/144/facebook-new.png" alt="facebook-new"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="fh5co-counters header">
                <div className="fh5co-narrow-content">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <CountUp className="fh5co-counter" end={misc['services']} duration={2} delay={0} />
                            <span className="fh5co-counter-label">{t('offered_services')}</span>
                        </div>
                        <div className="col-md-4 text-center">
                            <CountUp className="fh5co-counter" end={misc['clients']} duration={2} delay={0} />
                            <span className="fh5co-counter-label">{t('partners')}</span>
                        </div>
                        <div className="col-md-4 text-center">
                            <CountUp className="fh5co-counter" end={misc['deals']} duration={2} delay={0} />
                            <span className="fh5co-counter-label">{t('deals')}</span>
                        </div>
                    </div>
                </div>
            </div>


            {/* <Carousel {...properties}>
                {final_slides}
            </Carousel> */}

            <img src={home}
                    style={{ "width": "100%", "maxHeight": "80em", "margin": "auto" }}
                    alt="Գլխավոր նկար-պորտֆոլիո"
                    className="img-responsive animated fadeInLeft" />


            <div className="fh5co-narrow-content">
                {/* <img src={akcia}
                    style={{ "maxWidth": "100%", "maxHeight": "80em", "margin": "auto", paddingBottom: "16px" }}
                    alt="ԱԿՑԻԱ"
                    className="img-responsive animated fadeInLeft" /> */}
                <h2 className="fh5co-heading animated fadeIn">{t('our_clients')}</h2>
                <img src={clients}
                    style={{ "maxWidth": "100%", "margin": "auto" }}
                    alt={t('our_clients')}
                    className="img-responsive animated fadeInLeft" />

                <br></br>

                <h2 className="fh5co-heading animated fadeIn">{t('slogan')}</h2>
                <Masonry columns={{xs: 1, sm: 2, md: 3}} spacing={4} sx={{mx: 'auto'}}>
                    {final_products}
                </Masonry>
            </div>
        </div>
    );
}

export default App;